<template>
  <div class="px-2 text-xs text-gray-600 font-medium rounded flex flex-col items-center justify-center ring-2 ring-white">
    <div :class="[color, 'font-extrabold text-xl']">{{ token.rarityGrade.grade }}</div>
    <div>{{ token.rarityRank }}</div>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import colorMap from './colors'

const props = defineProps({ token: Object })
const color = computed(() => colorMap[props.token.rarityGrade.label])
</script>