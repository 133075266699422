<template>
  <!-- Profile header -->
  <div>
    <div>
      <img class="h-36 w-full object-cover object-top md:h-48 lg:h-64" :src="drop.coverImg" alt="" />
    </div>
    <div class="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
      <div class="-mt-6 sm:-mt-6 sm:flex sm:items-start sm:space-x-5">
        <div class="flex">
          <img class="h-24 w-24 rounded-full ring-4 ring-white sm:h-32 sm:w-32" :src="drop.avatarImg" alt="" />
        </div>
        <div class="mt-6 sm:flex-1 sm:min-w-0 sm:flex sm:items-start sm:justify-end sm:space-x-6 sm:pb-1">
          <div class="sm:hidden 2xl:block mt-6 min-w-0 flex-1">
            <h1 class="text-2xl font-bold text-gray-900 truncate">
              {{ drop.name }}
            </h1>
            <div class="mt-3 text-gray-700">
              {{ drop.description }}
            </div>
          </div>
          <div class="mt-6 flex justify-stretch flex-row space-x-4">
            <AppLink :to="{ name: 'ExploreDrop', params: { slug: drop.slug }}" class="flex-1 sm:flex-0 inline-flex justify-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500">
              <TicketIcon class="-ml-1 mr-2 h-5 w-5 text-gray-400" aria-hidden="true" />
              <span>Mint</span>
            </AppLink>
            <a href="https://discord.gg/4SSJWSdsUk" target="_blank" class="flex-1 sm:flex-0 inline-flex justify-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500">
              <DiscordIcon class="-ml-1 mr-2 h-5 w-5 text-gray-400" aria-hidden="true" />
              <span>Discord</span>
            </a>
          </div>
        </div>
      </div>
      <div class="hidden sm:block 2xl:hidden mt-6 min-w-0 flex-1">
        <h1 class="text-2xl font-bold text-gray-900 truncate">
          {{ drop.name }}
        </h1>
        <div class="mt-3 text-gray-700">
          {{ drop.description }}
        </div>
      </div>
    </div>
  </div>

  <div class="mt-6 max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
    <div class="rounded-md bg-blue-50 p-4">
      <div class="flex">
        <div class="flex-shrink-0">
          <InformationCircleIcon class="h-5 w-5 text-blue-400" aria-hidden="true" />
        </div>
        <div class="ml-3 flex-1 md:flex md:justify-between">
          <p class="text-sm text-blue-700">
            This mint is still in progress. It may take a few minutes for newly minted tokens to appear on this page.
          </p>
        </div>
      </div>
    </div>
  </div>

  <div class="py-8 xl:py-10 px-4 sm:px-6 max-w-5xl mx-auto">
    <div class="flex justify-between sm:justify-end space-x-4">
      <div class="sm:w-36">
        <div class="relative rounded-md shadow-sm">
          <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <HashtagIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
          </div>
          <input type="number" v-model="tokenId" class="focus:ring-green-500 focus:border-green-500 block w-full pl-10 sm:text-sm border-gray-300 rounded-md" placeholder="Jump to ID" />
        </div>
      </div>
      <div class="w-48">
        <Listbox as="div" v-model="sortBy">
          <div class="relative">
            <ListboxButton class="bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-green-500 focus:border-green-500 sm:text-sm">
              <span class="block truncate">{{ sortBy.name }}</span>
              <span class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                <SelectorIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
              </span>
            </ListboxButton>

            <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
              <ListboxOptions class="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                <ListboxOption as="template" v-for="order in sortOptions" :key="order.name" :value="order" v-slot="{ active, selected }">
                  <li :class="[active ? 'text-white bg-green-600' : 'text-gray-900', 'cursor-default select-none relative py-2 pl-3 pr-9']">
                    <span :class="[selected ? 'font-semibold' : 'font-normal', 'block truncate']">
                      {{ order.name }}
                    </span>

                    <span v-if="selected" :class="[active ? 'text-white' : 'text-green-600', 'absolute inset-y-0 right-0 flex items-center pr-4']">
                      <CheckIcon class="h-5 w-5" aria-hidden="true" />
                    </span>
                  </li>
                </ListboxOption>
              </ListboxOptions>
            </transition>
          </div>
        </Listbox>
      </div>
    </div>
    <div class="mt-4 grid grid-cols-3 gap-4">
      <!-- <aside class="col-span-1">
        <section class="bg-gray-50 rounded-lg">
          <div class="px-4 py-5 sm:p-6">
            <h2 class="text-base font-medium text-gray-900" id="countdown-title">
              Filters
            </h2>
          </div>
        </section>
      </aside> -->
      <main class="col-span-3">
        <ApolloQuery
          :query="listTokens"
          :variables="variables"
          v-slot="{ result: { data }, isLoading, query }">
          <div class="grid grid-cols-2 gap-y-12 gap-x-4 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8">
            <template v-if="isLoading && !isLoadingMore">
              <div v-for="n in 16" :key="n" class="group animate-pulse">
                <div class="w-full aspect-w-1 aspect-h-1 bg-gray-200 rounded-lg overflow-hidden"></div>
                <div class="mt-4 bg-gray-200 h-4 w-2/3 rounded"></div>
                <div class="mt-1 bg-gray-200 h-6 w-full rounded"></div>
              </div>
            </template>
            <template v-else>
              <div v-for="token in data.tokens" :key="token.id" class="group">
                <div class="relative">
                  <div class="w-full aspect-w-1 aspect-h-1 bg-gray-200 rounded-lg overflow-hidden">
                    <img :src="token.thumbnailUrl" class="w-full h-full object-center object-cover group-hover:opacity-75" />
                  </div>
                </div>
                <div class="flex justify-between mt-4">
                  <div>
                    <h3 class="text-sm text-gray-700">
                      {{ token.drop.name }}
                    </h3>
                    <p v-if="token.customName" class="text-lg font-medium text-gray-900 truncate">
                      {{ token.customName }}
                    </p>
                    <p v-else class="text-lg font-medium text-gray-900 truncate">
                      {{ token.drop.symbol }} #{{ token.tokenSeq + 1 }}
                    </p>
                  </div>
                  <RarityLabel :token="token" />
                </div>
              </div>

              <div v-if="hasMore" class="col-span-2 sm:col-span-3 lg:col-span-4 text-center">
                <DBtn primary lg @click="loadMore(query)" :color="drop.customization.primaryColor" :loading="!!isLoading">
                  Load more
                </DBtn>
              </div>
            </template>
          </div>
        </ApolloQuery>
      </main>
    </div>
  </div>
</template>

<script setup>
import { inject, ref, watch, computed } from 'vue'
import { Listbox, ListboxButton, ListboxLabel, ListboxOption, ListboxOptions } from '@headlessui/vue'
import { TicketIcon, InformationCircleIcon, CheckIcon, SelectorIcon, HashtagIcon} from '@heroicons/vue/solid'
import { DiscordIcon, DBtn } from '@ui'
import { listTokens } from '@/gql/tokens'
import AppLink from '@/components/roof/AppLink.vue'
import RarityLabel from '@/components/roof/tokens/rarity/RarityThumbnailLabel.vue'

const drop = inject('drop')
const sortOptions = [
  { name: 'Oldest first', value: { tokenSeq: 'asc' } },
  { name: 'Newest first', value: { tokenSeq: 'desc' } },
  { name: 'Rarity: High to Low', value: { rarityRank: 'asc' } },
  { name: 'Rarity: Low to High', value: { rarityRank: 'desc' } },
]

const tokenId = ref(null)
const sortBy = ref(sortOptions[1])
const hasMore = ref(true)
const isLoadingMore = ref(false)
const page = ref(0)

const variables = computed(() => {
  const base = {
    where: {
      dropId: { _eq: drop.value.id },
    },
    order: sortBy.value.value,
    limit: 32
  }
  if (tokenId.value) base.where.tokenSeq = {
    _eq: parseInt(tokenId.value, 10) - 1
  }
  return base
})

const loadMore = async (query) => {
  isLoadingMore.value = true

  await query.fetchMore({
    variables: {
      offset: ++page.value * 32
    },
    updateQuery: (prev, { fetchMoreResult }) => {
      if (fetchMoreResult.tokens.length < 32) {
        hasMore.value = false
      }
      return Object.assign({}, prev, {
        tokens: [...prev.tokens, ...fetchMoreResult.tokens]
      })
    }
  })
}

// If sort changes reset loading more
watch([sortBy, tokenId], () => {
  hasMore.value = !tokenId.value
  isLoadingMore.value = false
})
</script>