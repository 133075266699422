import gql from 'graphql-tag'

export const listTokens = gql`query listTokens ($where: tokens_bool_exp, $order: [tokens_order_by!], $limit: Int, $offset: Int) {
  tokens(where: $where, order_by: $order, limit: $limit, offset: $offset) {
    id
    tokenId
    tokenSeq
    customName
    thumbnailUrl
    fullResUrl
    metadataUrl
    rarityRank
    rarityGrade {
      label
      grade
    }
    drop {
      id
      name
      symbol
      slug
      creator {
        name
        avatarImg
      }
    }
  }
}`

export const tokenByPk = gql`query tokenByPk ($id: uuid!) {
  token: tokens_by_pk(id: $id) {
    id
    tokenId
    tokenSeq
    customName
    thumbnailUrl
    fullResUrl
    metadataUrl
    rarityRank
    rarityGrade {
      grade
      label
    }
    drop {
      id
      name
      description
      symbol
      slug
      supply
      contractAddress
      creator {
        name
        tagline
        avatarImg
        twitterUrl
        instagramUrl
        discordUrl
      }
      chain {
        symbol
      }
    }
    tokenTraits(order_by: { variation: { count: asc } }) {
      trait {
        label
      }
      variation {
        label
        count
      }
    }
  }
}`

export const ownerOf = gql`query ownerOf ($id: uuid!) {
  owner: ownerOfToken(id: $id) {
    address
    user {
      id
      displayName
      avatarImg
    }
  }
}`

export const orderbook = gql`query orderbook ($id: uuid!) {
  orderbook(id: $id) {
    id
    side
    currentPrice
    listingTime
    expirationTime
    makerAddress
    takerAddress
    approvedOnChain
    cancelled
    finalized
    markedInvalid
    maker {
      user {
        id
        displayName
        avatarImg
      }
    }
    taker {
      user {
        id
        displayName
        avatarImg
      }
    }
  }
}`